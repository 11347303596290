import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './components/pages/Home';
import About from './components/pages/About';

import Artists from './components/pages/Artists';
import ArtistPage from './components/pages/ArtistPage';

import NotFound from './components/pages/NotFound';

import Releases from './components/pages/Releases';
import ReleasePage from './components/pages/ReleasePage';
import Events from './components/pages/Events';
import Gallery from './components/pages/Gallery';

import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import Player from "./components/sidebar/Player";
import Footer from "./components/footer/Footer";

import { flipfield_volumes } from './data/tracks';
import { artist_map } from './data/artists';
import { allArtists } from './data/artists';
import { IframeUpdaterContext } from './components/widgets/IframeContext';

export default function App() {
	const [isSidebarActive, setIsSidebarActive] = useState(false);
	const [isHeaderBtnActive, setIsHeaderBtnActive] = useState(false);

	const handleLinkClick = () => {
		setIsSidebarActive(false);
		window.scrollTo(0, 0);
		setIsHeaderBtnActive(!isHeaderBtnActive);
	};

	const toggleSidebar = () => {
		setIsSidebarActive(!isSidebarActive);
		setIsHeaderBtnActive(!isHeaderBtnActive);
	};

	const [flipfieldVolumes, setFlipFieldVolumes] = useState([]);
	const [latestRelease, setLatestRelease] = useState({});
	const [latestReleaseTracks, setLatestReleaseTracks] = useState([]);
	const [artistMap, setArtistMap] = useState({});
	const { setIframeSrc, setIsPlaylist } = useContext(IframeUpdaterContext);

	useEffect(() => {
		setIframeSrc("1856109342")
		setIsPlaylist(true)
		setArtistMap(artist_map)
		setFlipFieldVolumes(flipfield_volumes)
		setLatestRelease(flipfieldVolumes[0])
		setLatestReleaseTracks(flipfield_volumes[0]["tracks"])
	}, []);

	return (
		<div>
			<Header toggleSidebar={toggleSidebar} isHeaderBtnActive={isHeaderBtnActive} />
			<Sidebar isSidebarActive={isSidebarActive} handleLinkClick={handleLinkClick} />
			<Player />
			<div className="App">
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/about" element={<About />} />
					<Route exact path="/artists" element={<Artists />} />
					<Route path="/artists/:slug" element={<ArtistPage />} />
					<Route exact path="/releases" element={<Releases flipfieldVolumes={flipfieldVolumes} />} />
					<Route path="/releases/:slug" element={<ReleasePage />} />
					<Route exact path="/events" element={<Events />} />
					<Route exact path="/gallery" element={<Gallery />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</div>
			<Footer />
		</div>
	);
}