export const flipfield_volumes = [
	{
		"title": "Flip Field Vol. 15",
		"slug": "flip-field-vol-15-popeye-meets-sinbad-the-sailor",
		"artist": "Popeye meets Sinbad the Sailor",
		"trackId": "1856109342",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-lQChRrxGasSp36hg-m0cvSg-t500x500.jpg",
		"description": "The main star of the 15th Flip Field sample challenge is the legendary character of our childhood: Popeye the Sailor. We've chosen the classic cartoon Popeye the Sailor meets Sindbad the Sailor from 1936. Open the cans of spinach, fill your pipes and listen to our super powerful tracks. Links in bio. Doot doot!",
		"tracks": [
			{
				"trackId": "1882967661",
				"title": "Twister Punch",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lQChRrxGasSp36hg-m0cvSg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/glvm-twister-punch",
				"username": "glvm-music"
			},
			{
				"trackId": "1882967640",
				"title": "Do The Popeye",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lQChRrxGasSp36hg-m0cvSg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/dreadscape-do-the-popeye",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1882967631",
				"title": "We Eat Spinach For Breakfast",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lQChRrxGasSp36hg-m0cvSg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/night-sunrise-we-eat-spinach",
				"username": "night-sunrise"
			},
			{
				"trackId": "1882967625",
				"title": "The Third Eye Of Nicholas Pope",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lQChRrxGasSp36hg-m0cvSg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bernardeel-the-third-eye-of-nicholas-pope",
				"username": "bernardeel"
			}
		],
	},
	{
		"title": "Flip Field Vol. 14",
		"slug": "flip-field-vol-14-golden-record",
		"artist": "Golden Record",
		"trackId": "1816891983",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-MKUH03Ldr1F1UG1F-ZaedJw-t500x500.jpg",
		"description": "Flip Field is back with a new EP: this time we present our reimaginings of the Golden Record. From footsteps to train engines, crying babies to laughter, a dog's bark to volcanic eruption, our world in sound is *flipped* and morphed into an eclectic hodgepodge of beats inspired by deep space.",
		"tracks": [
			{
				"trackId": "1810994328",
				"title": "Avoir de quoi",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-MKUH03Ldr1F1UG1F-ZaedJw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bernardeel-avoir-de-quoi",
				"username": "bernardeel"
			},
			{
				"trackId": "1810994313",
				"title": "Dream",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-MKUH03Ldr1F1UG1F-ZaedJw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/paper-dream",
				"username": "paperrr"
			},
			{
				"trackId": "1810994307",
				"title": "Gold Rush Boaah",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-MKUH03Ldr1F1UG1F-ZaedJw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/night-sunrise-gold-rush-boaah",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 13",
		"slug": "flip-field-vol-13-biotwang",
		"artist": "Biotwang",
		"trackId": "1771060437",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-RGbAFTzgaZerb61S-wnnWew-t500x500.jpg",
		"description": "Biotwang. What is that eerie sound emanating from the deepest parts of the ocean? Is it a whale searching for its lover? Or a cry for help from a mysterious entity?\nThe Flip Field collective has taken a deep dive into the Mariana Trench to analyze this strangely alluring 5-second recording. ",
		"tracks": [
			{
				"trackId": "1740557847",
				"title": "Bio Eye",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-RGbAFTzgaZerb61S-wnnWew-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bernardeel-bio-eye",
				"username": "bernardeel"
			},
			{
				"trackId": "1740557814",
				"title": "Out Of The Depths",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-RGbAFTzgaZerb61S-wnnWew-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/night-sunrise-out-of-the",
				"username": "night-sunrise"
			},
			{
				"trackId": "1740557793",
				"title": "Zabitibaz",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-RGbAFTzgaZerb61S-wnnWew-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bezcel-zabit-rabbit",
				"username": "luncell"
			},
			{
				"trackId": "1740557775",
				"title": "Mariana Trench",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-RGbAFTzgaZerb61S-wnnWew-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/theonlydjm-mariana-trench",
				"username": "theonlydjm"
			},
			{
				"trackId": "1740557757",
				"title": "The Abyss",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-RGbAFTzgaZerb61S-wnnWew-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/dreadscape-the-abyss",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1740557868",
				"title": "Don't Step",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-RGbAFTzgaZerb61S-wnnWew-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/paper-dont-step",
				"username": "paperrr"
			}
		],
	},
	{
		"title": "Flip Field Vol. 12",
		"slug": "flip-field-vol-12-new-world",
		"artist": "New World",
		"trackId": "1723732998",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-SXzzBXL2TZn90mTl-xk7CYQ-t500x500.jpg",
		"description": "November 17th, 1939: Under Nazi occupation, students in Prague organized a demonstration that, although violently suppressed, marked the beginning of Czech resistance against Nazi occupation during WW2. November 17th, 1989: A peaceful student commemoration of the events exactly 50 years prior escalated, becoming the start of the Velvet Revolution, ultimately leading to the downfall of the communist government in Czechoslovakia.",
		"tracks": [
			{
				"trackId": "1667933988",
				"title": "Gen D",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SXzzBXL2TZn90mTl-xk7CYQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/gen-d-bernardeel",
				"username": "bernardeel"
			},
			{
				"trackId": "1667933967",
				"title": "Blue",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SXzzBXL2TZn90mTl-xk7CYQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/blue-paper-1",
				"username": "paperrr"
			},
			{
				"trackId": "1667932779",
				"title": "Free Your Mind And Your Feet Will Follow",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SXzzBXL2TZn90mTl-xk7CYQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/free-your-mind-and-your-feet-1",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 11",
		"slug": "flip-field-vol-11-night-of-the-living-dead",
		"artist": "Night of the Living Dead",
		"trackId": "1709351463",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-y6bf1YtBK3UmPJtW-OsghXQ-t500x500.jpg",
		"description": "The Flip Field collective is back with a new compilation: this time we are moving to rural Pennsylvania, where a horde of hungry zombies awaits us. For the 11th challenge, Flip Field have sampled and twisted the main theme from the legendary zombie flick Night of the Living Dead (1968).",
		"tracks": [
			{
				"trackId": "1646629158",
				"title": "Jonny",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-y6bf1YtBK3UmPJtW-OsghXQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/jonny-paper",
				"username": "paperrr"
			},
			{
				"trackId": "1646629116",
				"title": "You're Hurting Me",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-y6bf1YtBK3UmPJtW-OsghXQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/youre-hurting-me-bernardeel",
				"username": "bernardeel"
			},
			{
				"trackId": "1646629089",
				"title": "Night Of The Living Trash",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-y6bf1YtBK3UmPJtW-OsghXQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/night-of-the-living-trash-glvm",
				"username": "glvm-music"
			},
			{
				"trackId": "1646629053",
				"title": "Some Kind Of Mass Murder",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-y6bf1YtBK3UmPJtW-OsghXQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/some-kind-of-mass-murder-night",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 10",
		"slug": "flip-field-vol-10-black-hole",
		"artist": "Black Hole",
		"trackId": "1678566714",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-9vUvOETyn0gej7Mf-3nKTVQ-t500x500.jpg",
		"description": "For our 10th compilation, we've sampled NASA's sonification of the black hole at the center of the Persius galaxy.",
		"tracks": [
			{
				"trackId": "1603464915",
				"title": "FRY",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-9vUvOETyn0gej7Mf-3nKTVQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/fry-bezcel",
				"username": "luncell"
			},
			{
				"trackId": "1601585775",
				"title": "Burakku",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-9vUvOETyn0gej7Mf-3nKTVQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/burakku-bernardeel",
				"username": "bernardeel"
			},
			{
				"trackId": "1601585739",
				"title": "Fold Space",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-9vUvOETyn0gej7Mf-3nKTVQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/fold-space-dreadscape",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1601585736",
				"title": "BBH",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-9vUvOETyn0gej7Mf-3nKTVQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bbh-glvm",
				"username": "glvm-music"
			},
			{
				"trackId": "1601585733",
				"title": "MAMA",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-9vUvOETyn0gej7Mf-3nKTVQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/mama-paper",
				"username": "paperrr"
			},
			{
				"trackId": "1601585715",
				"title": "Escaping The Black Hole",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-9vUvOETyn0gej7Mf-3nKTVQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/escaping-the-black-hole-night",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 9",
		"slug": "flip-field-vol-9-dial-up",
		"artist": "Dial Up",
		"trackId": "1661039190",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-gX5xaPNjYE3SpnfA-Li3RLA-t500x500.jpg",
		"description": "EEEeeeeerrrrrRRRR [pause] krrrRIIIIiiiingSHHHHH [pause] BEEEboopbeepboop [pause] pssssshhhhhkrrrRRRRchchch [pause] dingDINGding [pause] shhhhhhhhh",
		"tracks": [
			{
				"trackId": "1578108918",
				"title": "1-800-DAZ-ZLIN",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-gX5xaPNjYE3SpnfA-Li3RLA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/1-800-daz-zlin-paper",
				"username": "paperrr"
			},
			{
				"trackId": "1578108914",
				"title": "Dial It Down",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-gX5xaPNjYE3SpnfA-Li3RLA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/dial-it-down-bernardeel",
				"username": "bernardeel"
			},
			{
				"trackId": "1578108902",
				"title": "Netrunner Beta",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-gX5xaPNjYE3SpnfA-Li3RLA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/netrunner-beta-dreadscape",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1578108898",
				"title": "Dial Up",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-gX5xaPNjYE3SpnfA-Li3RLA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/dial-up-glvm",
				"username": "glvm-music"
			},
			{
				"trackId": "1578108891",
				"title": "Modem Talking",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-gX5xaPNjYE3SpnfA-Li3RLA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/modem-talking-night-sunrise",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 8",
		"slug": "flip-field-vol-8-james-bond",
		"artist": "James Bond",
		"trackId": "1641651955",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-sd2k26b2w47JI43X-5uLXfg-t500x500.jpg",
		"description": "This time we are sampling a Gunbarrel Sequence Compilation from James Bond films (1962-2015). Due to the fact that the first Bond book was released in 1953, this challenge is also a tribute to the legendary agent 007.",
		"tracks": [
			{
				"trackId": "1548021700",
				"title": "Live Or Let Die",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-sd2k26b2w47JI43X-5uLXfg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/live-or-let-die-night-sunrise",
				"username": "night-sunrise"
			},
			{
				"trackId": "1548021664",
				"title": "Together",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-sd2k26b2w47JI43X-5uLXfg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/together-bernardeel",
				"username": "bernardeel"
			},
			{
				"trackId": "1548021652",
				"title": "Severnaya",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-sd2k26b2w47JI43X-5uLXfg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/severnaya-dreadscape",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1548021631",
				"title": "If I Risk It All",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-sd2k26b2w47JI43X-5uLXfg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/if-i-risk-it-all-paper",
				"username": "paperrr"
			}
		],
	},
	{
		"title": "Flip Field Vol. 7",
		"slug": "flip-field-vol-7-the-wilhelm-scream",
		"artist": "The Wilhelm Scream",
		"trackId": "1620278317",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-SU3KNFoDy0sy7yXc-luVTEg-t500x500.jpg",
		"description": "What do Star Wars, Game of Thrones, The Simpsons, Grand Theft Auto 5, and The Witcher 3 have in common? They all include a stock sound effect called The Wilhelm Scream which was first used 70 years ago in the Western film 'The Charge at Feather River'. Flip Field has decided to honor the 70th anniversary of The Wilhelm Scream and use it for our 7th sample flip challenge.",
		"tracks": [
			{
				"trackId": "1512760717",
				"title": "Real Treat",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SU3KNFoDy0sy7yXc-luVTEg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/real-treat-bernardeel",
				"username": "bernardeel"
			},
			{
				"trackId": "1512760696",
				"title": "Alligator",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SU3KNFoDy0sy7yXc-luVTEg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/alligator-dreadscape",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1512760687",
				"title": "Ivy",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SU3KNFoDy0sy7yXc-luVTEg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/ivy-equi-i-l",
				"username": "equiil"
			},
			{
				"trackId": "1512760672",
				"title": "So Long",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SU3KNFoDy0sy7yXc-luVTEg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/so-long-paper",
				"username": "paperrr"
			},
			{
				"trackId": "1512760456",
				"title": "SLYTHR",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SU3KNFoDy0sy7yXc-luVTEg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/slythr-bezcel",
				"username": "luncell"
			},
			{
				"trackId": "1512760333",
				"title": "Wilhelm Vs The Gators Gang",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-SU3KNFoDy0sy7yXc-luVTEg-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/wilhelm-vs-the-gators-gang",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 6",
		"slug": "flip-field-vol-6-the-diva-dance",
		"artist": "Diva Dance",
		"trackId": "1601244901",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-5GOwd7RTKc6E9eSE-AKSJJQ-t500x500.jpg",
		"description": "For our 6th sample flip challenge, we rework the 'Diva Dance' scene from the beloved sci-fi film The Fifth Element.",
		"tracks": [
			{
				"trackId": "1490596696",
				"title": "LUVR",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-5GOwd7RTKc6E9eSE-AKSJJQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/luvr-bezcel",
				"username": "luncell"
			},
			{
				"trackId": "1490596675",
				"title": "Multipass",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-5GOwd7RTKc6E9eSE-AKSJJQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/multipass-dystopiant",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1490596666",
				"title": "54 Divas",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-5GOwd7RTKc6E9eSE-AKSJJQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/54-divas-night-sunrise",
				"username": "night-sunrise"
			},
			{
				"trackId": "1490596660",
				"title": "Touch",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-5GOwd7RTKc6E9eSE-AKSJJQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/touch-paper",
				"username": "paperrr"
			}
		],
	},
	{
		"title": "Flip Field Vol. 5",
		"slug": "flip-field-vol-5-pale-blue-dot",
		"artist": "Pale Blue Dot",
		"trackId": "1581787819",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-qIgtDzNYN76n2Opu-gSNevA-t500x500.jpg",
		"description": "Our 5th sample flip challenge features Carl Sagan's 'Pale Blue Dot' speech. Flip Field welcomed some new members this time around and the results are <3 <3 <3",
		"tracks": [
			{
				"trackId": "1465728670",
				"title": "Cosmic Braindance",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-qIgtDzNYN76n2Opu-gSNevA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/cosmic-braindance-dystpoiant",
				"username": "dreadscape-music"
			},
			{
				"trackId": "1465728661",
				"title": "Here, Home, Us",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-qIgtDzNYN76n2Opu-gSNevA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/here-home-us-paper",
				"username": "paperrr"
			},
			{
				"trackId": "1465728646",
				"title": "PBD & Sleep",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-qIgtDzNYN76n2Opu-gSNevA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/pbd-sleep-equi-i-l",
				"username": "equiil"
			},
			{
				"trackId": "1465728643",
				"title": "Privileged Position",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-qIgtDzNYN76n2Opu-gSNevA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/privileged-position-bezcel",
				"username": "luncell"
			},
			{
				"trackId": "1465728628",
				"title": "Save Us From Ourselves",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-qIgtDzNYN76n2Opu-gSNevA-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/save-us-from-ourselves-night",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 4",
		"slug": "flip-field-vol-4-v-sirem-poli-studanecka",
		"artist": "V Sirem Poli Studanecka",
		"trackId": "1566898363",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-tqPbynHUxzf8aWhP-QwrzWw-t500x500.jpg",
		"description": "Flipping Czech folk music in our 4th compilation. New challenge, same rules: one sample, one week, stock plugins only. Enjoy!",
		"tracks": [
			{
				"trackId": "1441602454",
				"title": "JOZKA",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-tqPbynHUxzf8aWhP-QwrzWw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/jozka-paper",
				"username": "paperrr"
			},
			{
				"trackId": "1441599673",
				"title": "Liquified Machine",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-tqPbynHUxzf8aWhP-QwrzWw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/liquified-machine-morethani",
				"username": "luncell"
			},
			{
				"trackId": "1441599661",
				"title": "Tension In Pension",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-tqPbynHUxzf8aWhP-QwrzWw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/tension-in-pension-bernardeel",
				"username": "bernardeel"
			},
			{
				"trackId": "1441599643",
				"title": "In The Wide",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-tqPbynHUxzf8aWhP-QwrzWw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/in-the-wide-night-sunrise",
				"username": "night-sunrise"
			}
		],
	},
	{
		"title": "Flip Field Vol. 3",
		"slug": "flip-field-vol-3-szomoru-vasarnap",
		"artist": "Szomorú Vasárnap",
		"trackId": "1556113012",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-lEBU3zAXgPoXiHSc-VLrvVw-t500x500.jpg",
		"description": "Enjoy this eclectic compilation of tracks made by resampling Szomorú Vasárnap by Seress Rezső also known as Hungarian Suicide Song.",
		"tracks": [
			{
				"trackId": "1425388255",
				"title": "Goulash",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lEBU3zAXgPoXiHSc-VLrvVw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/paper-goulash",
				"username": "paperrr"
			},
			{
				"trackId": "1425388228",
				"title": "He Entered",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lEBU3zAXgPoXiHSc-VLrvVw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/morethani-he-entered",
				"username": "luncell"
			},
			{
				"trackId": "1425388171",
				"title": "Monster's Trap",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lEBU3zAXgPoXiHSc-VLrvVw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/night-sunrise-monsters-trap",
				"username": "night-sunrise"
			},
			{
				"trackId": "1425388153",
				"title": "Sunbient",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-lEBU3zAXgPoXiHSc-VLrvVw-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bernardeel-sunbient",
				"username": "bernardeel"
			}
		],
	},
	{
		"title": "Flip Field Vol. 2",
		"slug": "flip-field-vol-2-christmas-time-is-here",
		"artist": "Christmas Time Is Here",
		"trackId": "1550201209",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-YhN4zrPzDqTuD3dm-qySV6A-t500x500.jpg",
		"description": "Happy Holidays! For this challenge, participants were given one week with a sample of 'Christmas Time Is Here' from the Charlie Brown Christmas Special. Only stock plugins were permitted! Enjoy!",
		"tracks": [
			{
				"trackId": "1416279613",
				"title": "Earthenware",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-YhN4zrPzDqTuD3dm-qySV6A-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bernardeel-earthenware",
				"username": "bernardeel"
			},
			{
				"trackId": "1416279610",
				"title": "Christmas Time Was Here",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-YhN4zrPzDqTuD3dm-qySV6A-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/night-sunrise-christmas-time",
				"username": "night-sunrise"
			},
			{
				"trackId": "1416279604",
				"title": "XXXmas",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-YhN4zrPzDqTuD3dm-qySV6A-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/paper-xxxmas",
				"username": "paperrr"
			}
		],
	},
	{
		"title": "Flipfield Vol. 1",
		"slug": "flip-field-vol-1-seikilos-epitaph",
		"artist": "Seikilos Epitaph",
		"trackId": "1542590593",
		"coverImageUrl": "https://i1.sndcdn.com/artworks-3kAVtFLCua8nGywZ-DD8kpQ-t500x500.jpg",
		"description": "Participants were given one week with a sample of 'Seikilos Epitaph', the oldest surviving complete musical composition. Each track incorporates the sample in a unique way. The results are eclectic. Enjoy.",
		"tracks": [
			{
				"trackId": "1405591888",
				"title": "Euterpe",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-3kAVtFLCua8nGywZ-DD8kpQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/bernardeel-euterpe",
				"username": "bernardeel"
			},
			{
				"trackId": "1405591882",
				"title": "Shine Till The End",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-3kAVtFLCua8nGywZ-DD8kpQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/night-sunrise-shine-till-the",
				"username": "night-sunrise"
			},
			{
				"trackId": "1405591876",
				"title": "Somebody Loves You",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-3kAVtFLCua8nGywZ-DD8kpQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/paper-somebody-loves-you",
				"username": "paperrr"
			},
			{
				"trackId": "1405591870",
				"title": "Daktylios",
				"coverImageUrl": "https://i1.sndcdn.com/artworks-3kAVtFLCua8nGywZ-DD8kpQ-t500x500.jpg",
				"url": "https://soundcloud.com/flipfield/cullen-doyle-daktylios",
				"username": "luncell"
			}
		],
	}
]