import React, { useState, createContext } from 'react';

export const IframeValueContext = createContext();
export const IframeUpdaterContext = createContext();

export function IframeProvider({ children }) {
  const [iframeSrc, setIframeSrc] = useState('');
  const [autoPlay, setAutoPlay] = useState("false");
  const [isPlaylist, setIsPlaylist] = useState(false);

  return (
    <IframeValueContext.Provider value={{ iframeSrc, autoPlay, isPlaylist }}>
      <IframeUpdaterContext.Provider value={{ setIframeSrc, setAutoPlay, setIsPlaylist }}>
        {children}
      </IframeUpdaterContext.Provider>
    </IframeValueContext.Provider>
  );
}
