import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IframeUpdaterContext } from '../widgets/IframeContext';

export default function Releases({ flipfieldVolumes }) {
	const { setIframeSrc, setAutoPlay, setIsPlaylist } = useContext(IframeUpdaterContext);

	const handlePlayClick = (trackId) => {
		setIsPlaylist(true)
		setIframeSrc(trackId);
		setAutoPlay("true")
	};

	return (
		<>
			<main className="main">
				<div className="container-fluid">
					<div className="row row--grid">
						<div className="col-12">
							<ul className="breadcrumb">
								<li className="breadcrumb__item"><Link to="/">Home</Link></li>
								<li className="breadcrumb__item breadcrumb__item--active">Releases</li>
							</ul>
						</div>

						<div className="col-12">
							<div className="main__title main__title--page">
								<h1>Flipfield Volumes</h1>
							</div>
						</div>
					</div>

					<div className="row row--grid">
						<div className="col-12">
							<div className="row row--grid">
								{flipfieldVolumes.map((track, index) => (
									<div className="col-6 col-sm-4 col-lg-2" key={index}>
										<div className="album">
											<div className="album__cover">
												<img src={track.coverImageUrl} alt="" />
												<Link onClick={() => handlePlayClick(track.trackId)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" /></svg></Link>
											</div>
											<div className="album__title">
												<h3><Link to={`/releases/${track.slug}`}>{track.title}</Link></h3>
												<span><Link to={`/releases/${track.slug}`}>{track.artist}</Link></span>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}