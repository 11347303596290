import { Link } from 'react-router-dom';

export default function About() {
	return (
		<>
			<main className="main">
				<div className="container-fluid">
					<div className="col-12">
						<ul className="breadcrumb">
							<li className="breadcrumb__item"><Link to="/">Home</Link></li>
							<li className="breadcrumb__item breadcrumb__item--active">About</li>
						</ul>
					</div>

					<div className="row row--grid">
						<div className="col-12">
							<div className="article article--page">
								<div className="article__content">
									<div className="article__artist">
										<img src={require('../../img/participate/participate.jpg')} alt="" />
										<div>
											<h1>About</h1>
											<span>Add your artistic vision to various sounds</span>
											<p>
												Flip Field is a Prague-based collective of music producers engaged in re-contextualizing various historic 
												audio files into fresh compositions.
											</p>
											<p>
												Flip Field started out of a desire for community and accountability. We noticed that our tracks only seemed 
												to (magically) get finished when there was a real deadline. As you approach this deadline, however, you spend 
												too much time with the work and start to lose your perspective. That's why feedback from other producers is needed 
												to help you move the track forward.
											</p>
											<p>
												Online communities are great, but it is also important for us to get together and see each other's 
												project files, discuss different approaches to music production, and motivate one another to make music 
												more often.
											</p>
											<p>
												Come and join us on the next flip, meet new people, learn new things and play live with us out in Prague.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}