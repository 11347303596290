import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import NotFound from './NotFound';
import { IframeUpdaterContext } from '../widgets/IframeContext';
import { flipfield_volumes } from '../../data/tracks';
import { artist_map } from '../../data/artists';

export default function ReleasePage() {
    const { slug } = useParams();
	const release = flipfield_volumes.find(r => r.slug === slug);

	const { setIframeSrc, setAutoPlay, setIsPlaylist } = useContext(IframeUpdaterContext);

	const handlePlayClick = (trackId) => {
		setIsPlaylist(false)
		setIframeSrc(trackId);
		setAutoPlay("true")
	};

	if (!release) {
		return (
			<>
				<NotFound />
			</>
		)
	}

	return (
		<>
			<main className="main">
				<div className="container-fluid">
					<div className="row row--grid">
						<div className="col-12">
							<ul className="breadcrumb">
								<li className="breadcrumb__item"><Link to="/">Home</Link></li>
								<li className="breadcrumb__item"><Link to="/releases">Releases</Link></li>
                                <li className="breadcrumb__item breadcrumb__item--active">{release.artist}</li>
							</ul>
						</div>

						<div className="col-12">
							<div className="main__title main__title--page">
								<h1>{release.title}</h1>
							</div>
						</div>
					</div>

					<div className="row row--grid">
						<div className="col-12">
							<div className="article article--page">
								<div className="article__content">
									<div className="article__artist">
										<img src={release.coverImageUrl} alt="" />
										<div>
											<h1>{release.artist}</h1>
											<p>{release.description}</p>
										</div>
									</div>
								</div>

								<div className="share">
									<a href="#" className="share__link share__link--fb"><svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.56341 16.8197V8.65888H7.81615L8.11468 5.84663H5.56341L5.56724 4.43907C5.56724 3.70559 5.63693 3.31257 6.69042 3.31257H8.09873V0.5H5.84568C3.1394 0.5 2.18686 1.86425 2.18686 4.15848V5.84695H0.499939V8.6592H2.18686V16.8197H5.56341Z" /></svg> share</a>
									<a href="#" className="share__link share__link--tw"><svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.55075 3.19219L7.58223 3.71122L7.05762 3.64767C5.14804 3.40404 3.47978 2.57782 2.06334 1.1902L1.37085 0.501686L1.19248 1.01013C0.814766 2.14353 1.05609 3.34048 1.843 4.14552C2.26269 4.5904 2.16826 4.65396 1.4443 4.38914C1.19248 4.3044 0.972149 4.24085 0.951164 4.27263C0.877719 4.34677 1.12953 5.31069 1.32888 5.69202C1.60168 6.22165 2.15777 6.74068 2.76631 7.04787L3.28043 7.2915L2.67188 7.30209C2.08432 7.30209 2.06334 7.31268 2.12629 7.53512C2.33613 8.22364 3.16502 8.95452 4.08833 9.2723L4.73884 9.49474L4.17227 9.8337C3.33289 10.321 2.34663 10.5964 1.36036 10.6175C0.888211 10.6281 0.5 10.6705 0.5 10.7023C0.5 10.8082 1.78005 11.4014 2.52499 11.6344C4.75983 12.3229 7.41435 12.0264 9.40787 10.8506C10.8243 10.0138 12.2408 8.35075 12.9018 6.74068C13.2585 5.88269 13.6152 4.315 13.6152 3.56293C13.6152 3.07567 13.6467 3.01212 14.2343 2.42953C14.5805 2.09056 14.9058 1.71983 14.9687 1.6139C15.0737 1.41264 15.0632 1.41264 14.5281 1.59272C13.6362 1.91049 13.5103 1.86812 13.951 1.39146C14.2762 1.0525 14.6645 0.438131 14.6645 0.258058C14.6645 0.22628 14.5071 0.279243 14.3287 0.374576C14.1398 0.480501 13.7202 0.639389 13.4054 0.734722L12.8388 0.914795L12.3247 0.565241C12.0414 0.374576 11.6427 0.162725 11.4329 0.0991699C10.8978 -0.0491255 10.0794 -0.0279404 9.59673 0.14154C8.2852 0.618204 7.45632 1.84694 7.55075 3.19219Z" /></svg> tweet</a>
								</div>
							</div>
						</div>
					</div>

                    <section className="row row--grid">
						<div className="col-12">
							<div className="main__title">
								<h2>Tracks</h2>
							</div>
						</div>

						{release["tracks"].map((track, index) => (
							<div className="col-6 col-sm-4 col-lg-2" key={index}>
								<div className="album">
									<div className="album__cover">
										<img src={track.coverImageUrl} alt="" />
										<a onClick={() => handlePlayClick(track.trackId)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" /></svg></a>
									</div>
									<div className="album__title">
										<h3>{track.title}</h3>
										<span><Link to={`/artists/${track.username}`}>{artist_map[track.username]}</Link></span>
									</div>
								</div>
							</div>
						))}
					</section>
				</div>
			</main>
		</>
	);
}