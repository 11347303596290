import { Link } from 'react-router-dom';

export default function Gallery() {
    const numImages = 24;
    const images = [];
    for (let i = 0; i < numImages; i++) {
        images.push(require(`../../img/gallery/${i}.jpg`))
    }
    return (
        <>
            <main className="main">
                <div className="container-fluid">
                    <div className="row row--grid">
                        <div className="col-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb__item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb__item breadcrumb__item--active">Gallery</li>
                            </ul>
                        </div>
                        <div className="col-12">
                            <div className="main__title main__title--page">
                                <h1>Gallery</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row row--grid">
                        <div className="col-12">
                            <div className="row row--grid">
                                {images.map((image) => (
                                    <div className="col-12 col-sm-6 col-lg-4">
                                        <div className="live">
                                            <a className="live__cover open-video">
                                                <img src={image} loading="lazy" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}