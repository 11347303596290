import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Profile from '../cards/Profile';
import { IframeUpdaterContext } from '../widgets/IframeContext';
import { flipfield_volumes } from '../../data/tracks';
import { artist_map } from '../../data/artists';
import { allArtists } from '../../data/artists';
import NotFound from './NotFound';

export default function ArtistPage() {
	const { setIframeSrc, setAutoPlay, setIsPlaylist } = useContext(IframeUpdaterContext);

	const handlePlayClick = (trackId) => {
		setIsPlaylist(false)
		setIframeSrc(trackId);
		setAutoPlay("true")
	};

	const findTracksByUsername = (volumes, username) => {
		return volumes.flatMap(volume =>
			volume.tracks.filter(track => track.username === username)
		);
	};

	const { slug } = useParams();
	const tracksByUsername = findTracksByUsername(flipfield_volumes, slug);
	const currentArtist = allArtists.find(r => r.username === slug);

	if (!artist_map[slug] || !currentArtist || tracksByUsername.length === 0) {
		return (
			<>
				<NotFound />
			</>
		)
	}

	// const numImages = 3;
	// const images = [];
	// for (let i = 0; i < numImages; i++) {
	// 	images.push(require(`../../img/profile/${slug}/${i}.jpg`))
	// }

	const profileImage = require(`../../img/artists/${slug}.jpg`);

	return (
		<>
			<main className="main">
				<div className="container-fluid">
					<div className="col-12">
						<ul className="breadcrumb">
							<li className="breadcrumb__item"><Link to="/artists">Artists</Link></li>
							<li className="breadcrumb__item breadcrumb__item--active">{currentArtist.display_name}</li>
						</ul>
					</div>
					<Profile
						image={profileImage}
						artist_name={currentArtist.display_name}
						full_name={currentArtist.full_name}
						description={currentArtist.description}
						instagram_url={currentArtist.instagram_url}
						soundcloud_url={currentArtist.soundcloud_url}
					/>

					<section className="row row--grid">
						<div className="col-12">
							<div className="main__title">
								<h2>Releases</h2>
							</div>
						</div>

						{tracksByUsername.map((track, index) => (
							<div className="col-6 col-sm-4 col-lg-2" key={index}>
								<div className="album">
									<div className="album__cover">
										<img src={track.coverImageUrl} alt="" />
										<a onClick={() => handlePlayClick(track.trackId)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" /></svg></a>
									</div>
									<div className="album__title">
										<h3>{track.title}</h3>
										<span>{artist_map[track.username]}</span>
									</div>
								</div>
							</div>
						))}
					</section>

					{/* <section className="row row--grid">
						<div className="col-12">
							<div className="main__title">
								<h2>Gallery</h2>
							</div>
						</div>

						<div className="row row--grid">
							<div className="col-12">
								<div className="row row--grid">
									{images.map((image) => (
										<div className="col-12 col-sm-6 col-lg-4">
											<div className="live">
												<a className="live__cover open-video">
													<img src={image} loading="lazy" alt="" />
												</a>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</section> */}
				</div>
			</main>
		</>
	);
}