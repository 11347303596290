import { Link } from 'react-router-dom';
import Logo from '../../img/flip-logo-new.svg';

export default function Header({ toggleSidebar, isHeaderBtnActive }) {
	return (
		<>
			<header className="header">
				<div className="header__content">
					<div className="header__logo">
						<Link to="/">
							<img src={Logo} alt="" />
						</Link>
					</div>

					<nav className="header__nav">
						<Link to="/releases/flip-field-vol-15-popeye-meets-sinbad-the-sailor">Latest Release</Link>
						<Link to="mailto:flipfieldcollective@gmail.com">Contact Us</Link>
					</nav>

					<button className={`header__btn ${isHeaderBtnActive ? 'header__btn--active' : ''}`} type="button" onClick={toggleSidebar}>
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>
			</header>
		</>
	)
}