import { StyledDiv } from "../widgets/StyledDiv";
import { Link } from 'react-router-dom';

export default function NotFound() {
	return (
		<>
			<main className="main">
				<div className="container-fluid">
					<div className="row row--grid">
						<div className="hero">
							<StyledDiv
								className="hero__slide"
								imagePath={require('../../img/videos/ff_rotating.gif')}
							>
								<h1 className="hero__title">404: Oops!</h1>
								<p className="hero__text">How many beers have you smoked?</p>
								<div className="hero__btns">
									<Link to="/" className="hero__btn hero__btn--green">Go Home</Link>
								</div>
							</StyledDiv>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}