import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../img/flip-logo-new.svg';
import { ReactComponent as HomeIcon } from '../../img/sidebar/home.svg';
import { ReactComponent as AboutIcon } from '../../img/sidebar/about.svg';
import { ReactComponent as ArtistsIcon } from '../../img/sidebar/artists.svg';
import { ReactComponent as ReleasesIcon } from '../../img/sidebar/releases.svg';
import { ReactComponent as EventsIcon } from '../../img/sidebar/events.svg';
import { ReactComponent as GalleryIcon } from '../../img/sidebar/gallery.svg';

export default function Sidebar({ isSidebarActive, handleLinkClick }) {
	const location = useLocation();

	const isLinkActive = (pathname) => {
		return location.pathname === pathname;
	};

	return (
		<>
			<div className={`sidebar ${isSidebarActive ? 'sidebar--active' : ''}`}>
				<div className="sidebar__logo">
					<img src={Logo} alt="" />
				</div>

				<ul className="sidebar__nav">
					<li className="sidebar__nav-item">
						<Link
							to="/"
							onClick={handleLinkClick}
							className={`sidebar__nav-link ${isLinkActive('/') ? 'sidebar__nav-link--active' : ''}`}
						>
							<HomeIcon />
							<span>Home</span>
						</Link>
					</li>

					<li className="sidebar__nav-item">
						<Link
							to="/about"
							onClick={handleLinkClick}
							className={`sidebar__nav-link ${isLinkActive('/about') ? 'sidebar__nav-link--active' : ''}`}
						>
							<AboutIcon />
							<span>About</span>
						</Link>
					</li>

					<li className="sidebar__nav-item">
						<Link
							to="/artists"
							onClick={handleLinkClick}
							className={`sidebar__nav-link ${isLinkActive('/artists') ? 'sidebar__nav-link--active' : ''}`}
						>
							<ArtistsIcon />
							<span>Artists</span>
						</Link>
					</li>

					<li className="sidebar__nav-item">
						<Link
							to="/releases"
							onClick={handleLinkClick}
							className={`sidebar__nav-link ${isLinkActive('/releases') ? 'sidebar__nav-link--active' : ''}`}
						>
							<ReleasesIcon />
							<span>Releases</span>
						</Link>
					</li>

					<li className="sidebar__nav-item">
						<Link
							to="/events"
							onClick={handleLinkClick}
							className={`sidebar__nav-link ${isLinkActive('/events') ? 'sidebar__nav-link--active' : ''}`}
						>
							<EventsIcon />
							<span>Events</span>
						</Link>
					</li>

					<li className="sidebar__nav-item">
						<Link
							to="/gallery"
							onClick={handleLinkClick}
							className={`sidebar__nav-link ${isLinkActive('/gallery') ? 'sidebar__nav-link--active' : ''}`}
						>
							<GalleryIcon />
							<span>Gallery</span>
						</Link>
					</li>
				</ul>
			</div>
		</>
	);
}
