
import React from 'react';

export function StyledDiv({ children, imagePath, className }) {
  const divStyle = {
    backgroundImage: `url(${imagePath})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  };

  return (
    <div style={divStyle} className={className}>
      {children}
    </div>
  );
};