import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { IframeUpdaterContext } from '../widgets/IframeContext';
import { allArtists } from '../../data/artists';
import { flipfield_volumes } from '../../data/tracks';
import { artist_map } from '../../data/artists';
import { artistSlider, heroSlider } from '../../config/sliderSettings';
import { StyledDiv } from '../widgets/StyledDiv';

export default function Home() {
	const { setIframeSrc, setAutoPlay, setIsPlaylist } = useContext(IframeUpdaterContext);

	const [artists, setArtists] = useState([]);

	useEffect(() => {
		setArtists(allArtists);
	}, []);

	const handlePlayClick = (trackId) => {
		setIsPlaylist(false);
		setIframeSrc(trackId);
		setAutoPlay("true")
	};

	return (
		<>
			<main className="main">
				<div className="container-fluid">

					{/* Hero Slider */}
					<section className="row">
						<div className="hero">
							<div className="col-12">
								<Slider {...heroSlider}>
									<StyledDiv
										className="hero__slide"
										imagePath={require('../../img/covers/cover_15.jpg')}
									>
										<h1 className="hero__title">Flip Field Volume 15 Out Now!</h1>
										<p className="hero__text">Flip Field is back with a new EP, this time we venture to the sea, powered by spinach!</p>
										<div className="hero__btns">
											<Link to="/releases/flip-field-vol-15-popeye-meets-sinbad-the-sailor" className="hero__btn hero__btn--green">Listen Now</Link>
										</div>
									</StyledDiv>
									<StyledDiv
										className="hero__slide"
										imagePath={require('../../img/videos/ff_rotating.gif')}
									>
										<h1 className="hero__title">Welcome to Flip Field</h1>
										<p className="hero__text">Journey through multiple genre music built around various historical and modern samples</p>
										<div className="hero__btns">
											<Link to="/releases" className="hero__btn hero__btn--green">Listen Now</Link>
											<Link to="/about" className="hero__btn">About Us</Link>
										</div>
									</StyledDiv>
								</Slider>
							</div>
						</div>
					</section>
					{/* End Hero Slider */}

					{/* Latest Flip Release */}
					<section className="row row--grid">
						<div className="col-12">
							<div className="main__title">
								<h2>Flipfield Volume 15 - Popeye meets Sinbad the Sailor</h2>

								<Link to="/releases" className="main__link">See all <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" /></svg></Link>
							</div>
						</div>

						{flipfield_volumes[0]["tracks"].map((track, index) => (
							<div className="col-6 col-sm-4 col-lg-2" key={index}>
								<div className="album">
									<div className="album__cover">
										<img src={track.coverImageUrl} alt="" />
										<a onClick={() => handlePlayClick(track.trackId)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" /></svg></a>
									</div>
									<div className="album__title">
										<h3><Link to={`/releases/${flipfield_volumes[0].slug}`}>{track.title}</Link></h3>
										<span><Link to={`/artists/${track.username}`}>{artist_map[track.username]}</Link></span>
									</div>
								</div>
							</div>
						))}

					</section>
					{/* End New Releases */}

					{/* Events */}
					<section className="row row--grid">
						<div className="col-12">
							<div className="main__title">
								<h2>Upcoming Events</h2>

								<Link to="/events" className="main__link">See all <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" /></svg></Link>
							</div>
						</div>

						<div className="row row--grid">
							<div className="col-12">
								<div className="row row--grid">
									<div className="col-12 col-md-12 col-xl-12">
										<StyledDiv className="event" imagePath={require("../../img/events/cross-club.jpg")}>
											<span className="event__date">TBD</span>
											<span className="event__time">7:00 pm</span>
											<h3 className="event__title"><a href="event.html">Flip Field Live 5 | Cross Club</a></h3>
											<p className="event__address">Plynární 1096, 170 00 Praha 7-Holešovice</p>
										</StyledDiv>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* End Events */}

					{/* Artists */}
					<section className="row row--grid">
						<div className="col-12">
							<div className="main__title">
								<h2>Artists</h2>

								<Link to="/artists" className="main__link">See all <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" /></svg></Link>
							</div>
						</div>

						<div className="col-12">
							<Slider {...artistSlider}>
								{artists.map((artist, index) => (
									<Link to={`/artists/${artist.username}`} className="artist" key={index}>
										<div className="artist__cover">
											<img src={require(`../../img/artists/${artist.username}.jpg`)} alt="" />
										</div>
										<h3 className="artist__title">{artist.display_name}</h3>
									</Link>
								))}
							</Slider>
						</div>
					</section>
					{/* End Artists */}
				</div>
			</main>
		</>
	);
}