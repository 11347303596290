import { Link } from 'react-router-dom';
import { StyledDiv } from '../widgets/StyledDiv';

export default function Events() {
	return (
		<>
			<main className="main">
				<div className="container-fluid">
					<div className="row row--grid">
						<div className="col-12">
							<ul className="breadcrumb">
								<li className="breadcrumb__item"><Link to="/">Home</Link></li>
								<li className="breadcrumb__item breadcrumb__item--active">Events</li>
							</ul>
						</div>

						<div className="col-12">
							<div className="main__title main__title--page">
								<h1>Events</h1>
							</div>
						</div>
					</div>

					<div className="row row--grid">
						<div className="col-12">
							<div className="row row--grid">
								<div className="col-12 col-md-6 col-xl-4">
									<StyledDiv className="event" imagePath={require("../../img/events/cross-club.jpg")}>
										<span className="event__date">TBD</span>
										<span className="event__time">7:00 pm</span>
										<h3 className="event__title"><a href="event.html">Flip Field Live 5</a></h3>
										<p className="event__address">Plynární 1096, 170 00 Praha 7-Holešovice</p>
									</StyledDiv>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<div className="partners owl-carousel">
								<a href="#" className="partners__img">
									<img src="img/partners/3docean-light-background.png" alt="" />
								</a>

								<a href="#" className="partners__img">
									<img src="img/partners/activeden-light-background.png" alt="" />
								</a>

								<a href="#" className="partners__img">
									<img src="img/partners/audiojungle-light-background.png" alt="" />
								</a>

								<a href="#" className="partners__img">
									<img src="img/partners/codecanyon-light-background.png" alt="" />
								</a>

								<a href="#" className="partners__img">
									<img src="img/partners/photodune-light-background.png" alt="" />
								</a>

								<a href="#" className="partners__img">
									<img src="img/partners/themeforest-light-background.png" alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}