import React, { useState, useEffect, useRef, useContext } from 'react';
import { IframeValueContext } from '../widgets/IframeContext';
import { ReactComponent as PlayerIcon } from '../../img/player/player.svg';

export default function Player() {
  const { iframeSrc, autoPlay, isPlaylist } = useContext(IframeValueContext);
  const iframeRef = useRef();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const baseUrl = "https://w.soundcloud.com/player/";
    let trackUrl = `https://api.soundcloud.com/tracks/${iframeSrc}`;
    if (isPlaylist) {
      trackUrl = `https://api.soundcloud.com/playlists/${iframeSrc}`;
    }
    const config = `&color=%23000000&auto_play=${autoPlay}&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false`;

    const newUrl = `${baseUrl}?url=${encodeURIComponent(trackUrl)}${config}`;

    if (iframeRef.current.src !== newUrl) {
      iframeRef.current.src = newUrl;
    }
  }, [iframeSrc, autoPlay, isPlaylist]);

  const togglePlayer = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className={`player ${isActive ? 'player--active' : ''}`}>
        <iframe
          title='Soundcloud Player'
          ref={iframeRef}
          width="100%"
          height="300"
          scrolling="auto"
          frameBorder="no"
          allow="autoplay"
        ></iframe>
      </div>
      <button
        className={`player__btn ${isActive ? 'player__btn--active' : ''}`}
        type="button"
        onClick={togglePlayer}
      >
        <PlayerIcon />
        Player
      </button>
    </>
  );
}
