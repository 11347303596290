import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { allArtists } from '../../data/artists';

export default function Artists() {
	const [artists, setArtists] = useState([]);

	useEffect(() => {
		setArtists(allArtists)
	}, []);

	return (
		<>
			<main className="main">
				<div className="container-fluid">
					<div className="row row--grid">
						<div className="col-12">
							<ul className="breadcrumb">
								<li className="breadcrumb__item"><Link to="/">Home</Link></li>
								<li className="breadcrumb__item breadcrumb__item--active">Artists</li>
							</ul>
						</div>

						<div className="col-12">
							<div className="main__title main__title--page">
								<h1>Artists</h1>
							</div>
						</div>
					</div>

					<div className="row row--grid">
						<div className="col-12">
							<div className="row row--grid">
								{artists.map((artist, index) => (
									<div className="col-6 col-sm-4 col-md-3 col-xl-2" key={index}>
										<Link to={`/artists/${artist.username}`} className="artist">
											<div className="artist__cover">
												<img src={require(`../../img/artists/${artist.username}.jpg`)} alt="" />
											</div>
											<h3 className="artist__title">{artist.display_name}</h3>
										</Link>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}