export const allArtists = [
    {
        "username": "bernardeel",
        "instagram_url": "https://www.instagram.com/bernardeel.sound",
        "soundcloud_url": "https://soundcloud.com/bernardeel",
        "display_name": "Bernardeel",
        "full_name": "Jakub Řehoř",
        "description": "Bernardeel is a psybient artist whose sound is inspired by left-field electronic artists like Somatoast, Entangled Mind, Faelow or Mindex. With intricate layering of field recordings with neuro basses, meditative melodies, and heavy atmospheres, Bernardeel creates a genre-bending world of psychedelic, glitch, IDM and ambient music.",
    },
    {
        "username": "luncell",
        "instagram_url": "https://www.instagram.com/luncel_odley",
        "soundcloud_url": "https://soundcloud.com/luncell",
        "display_name": "Luncel",
        "full_name": "Cullen Doyle",
        "description": "",
    },
    {
        "username": "dreadscape-music",
        "instagram_url": "https://www.instagram.com/dreadscapemusic",
        "soundcloud_url": "https://soundcloud.com/dreadscape-music",
        "display_name": "Dreadscape",
        "full_name": "Chris Andrews",
        "description": "Dreadscape’s sound is a dark and compelling blend of electro beats, characterized by its depth and intensity. Drawing heavy inspiration from the groundbreaking work of Zone Records artists like Gesaffelstein, Djedjotronic, and Jensen Interceptor, Dreadscape crafts immersive tracks that transport listeners to uncharted auditory landscapes. Each beat is a testament to Dreadscapes's dedication to pushing the boundaries of electronic music, striving to create a unique sonic experience that resonates on a profound level.",
    },
    {
        "username": "equiil",
        "instagram_url": "https://www.instagram.com/equiil",
        "soundcloud_url": "https://soundcloud.com/equiil",
        "display_name": "Equīīl",
        "full_name": "Brendan Moratz",
        "description": "",
    },
    {
        "username": "glvm-music",
        "instagram_url": "https://www.instagram.com/glvm.music",
        "soundcloud_url": "https://soundcloud.com/glvm-music",
        "display_name": "GLVM",
        "full_name": "Petr Knedlík",
        "description": "Dance music that will make you cry. Or maybe it won't, that's up to you.",
    },
    {
        "username": "night-sunrise",
        "instagram_url": "https://www.instagram.com/nightsunrisemusic",
        "soundcloud_url": "https://soundcloud.com/night-sunrise",
        "display_name": "Night Sunrise",
        "full_name": "František Formánek",
        "description": "Night Sunrise's music is characterized by ethereal pads and groovy rhythms. Inspired by the likes of J Dilla, Timbaland and DJ Rashad, the producer's drums are wonky, the percussion is off-kilter and the rhythms keep changing. Same as the artist's genre classifiers: Night Sunrise is hip-hop, IDM, techno, house, footwork or ambient. Yet, the overall atmosphere remains the same: expect something between a calm, sunny beach with a Tequila Sunrise in your hand and late-night walks in a bustling, neon-lit city.",
    },
    {
        "username": "paperrr",
        "instagram_url": "https://www.instagram.com/___paper__",
        "soundcloud_url": "https://soundcloud.com/paperrr",
        "display_name": "PAPER.",
        "full_name": "Pavel Ray Czernek",
        "description": "",
    },
    {
        "username": "theonlydjm",
        "instagram_url": "https://www.instagram.com/theonlydjm",
        "soundcloud_url": "https://soundcloud.com/theonlydjm",
        "display_name": "The Only DJM",
        "full_name": "Daniel Martens",
        "description": "",
    },
]

export const artist_map = {
    "bernardeel": "Bernardeel",
    "luncell": "Luncel",
    "dreadscape-music": "Dreadscape",
    "equiil": "Equīīl",
    "glvm-music": "GLVM",
    "night-sunrise": "Night Sunrise",
    "paperrr": "PAPER.",
    "theonlydjm": "The Only DJM"
}